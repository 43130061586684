import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import { ContentNodeType, HubPost, PaginationProps } from '@/lib/types'
import initSdk from '@/lib/utils/initSdk'

export const fetchPostsForHub = cache(
  async ({
    publicKey,
    pagination = { limit: 5, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  }: {
    publicKey: string
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    await initSdk()

    const hubPostsResponse = await Nina.Hub.fetchPosts(
      publicKey,
      pagination,
      withAccountData,
    )

    if (hubPostsResponse?.posts) {
      hubPostsResponse.posts = hubPostsResponse.posts.map((post: HubPost) => {
        post.type = ContentNodeType.Post

        return post
      })
    }

    return hubPostsResponse
  },
)
