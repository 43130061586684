import { PublicKeyString } from '../types'
import initSdk from '../utils/initSdk'

export const addPlayEvent = async (releasePublicKey: PublicKeyString) => {
  await initSdk()
  const sessionSignature = localStorage.getItem('sessionSignature')

  if (!sessionSignature) {
    // throw new Error('Session signature not found')
    return
  }

  const { message, signature, publicKey } = JSON.parse(sessionSignature)

  const notifications = await fetch(`${process.env.NINA_ID_ENDPOINT}/plays`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message,
      signature,
      publicKey,
      releasePublicKey,
    }),
  })

  return (await notifications.json()) as { success: boolean }
}
