import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, PaginationProps, Post } from '../types'

export const fetchPostsForAccount = cache(
  async ({
    publicKey,
    pagination = { limit: 5, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  }: {
    publicKey: string
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    await initSdk()

    const accountResponse = await Nina.Account.fetchPosts(
      publicKey,
      pagination,
      withAccountData,
    )

    if (accountResponse?.posts) {
      accountResponse.posts = accountResponse.posts.map((post: Post) => {
        post.type = ContentNodeType.Post

        return post
      })
    }

    return accountResponse
  },
)
