import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import { ContentNodeType, FetchReleaseResponse } from '@/lib/types'
import initSdk from '@/lib/utils/initSdk'

export const fetchRelease = cache(
  async (releasePublicKey: string, withAccountData = true) => {
    await initSdk()

    const releasesResponse = await Nina.Release.fetch(
      releasePublicKey,
      undefined,
      withAccountData,
    )

    if (releasesResponse?.release) {
      releasesResponse.release.type = ContentNodeType.Release
    }

    return releasesResponse as FetchReleaseResponse
  },
)
