import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, HubRelease, PaginationProps } from '../types'

export const fetchReleasesForHub = cache(
  async ({
    publicKey,
    pagination = { limit: 5, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  }: {
    publicKey: string
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    try {
      await initSdk()

      const hubReleases = await Nina.Hub.fetchReleases(
        publicKey,
        pagination,
        withAccountData,
      )

      if (hubReleases?.releases) {
        hubReleases.releases = hubReleases.releases.map(
          (release: HubRelease) => {
            release.type = ContentNodeType.HubRelease

            return release
          },
        )
      }

      return hubReleases
    } catch (error) {
      console.warn(error)

      return { releases: [] }
    }
  },
)
