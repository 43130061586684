import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import { ContentNodeType } from '@/lib/types'
import initSdk from '@/lib/utils/initSdk'

export const fetchPost = cache(
  async (postPublicKey: string, withAccountData = false) => {
    await initSdk()

    const postsResponse = await Nina.Post.fetch(postPublicKey, withAccountData)

    if (postsResponse?.post) {
      postsResponse.post.type = ContentNodeType.Post
    }

    return postsResponse
  },
)
