import { useEffect, useState } from 'react'

function getScreenWidth() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [windowWidth, setWindowWidth] = useState<number | null>(null)
  const isWindow = typeof window !== 'undefined'
  const getWidth = () => (isWindow ? window.innerWidth : windowWidth)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const resize = () => setWindowWidth(getWidth())
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isWindow) {
      setWindowWidth(getWidth())

      window.addEventListener('resize', resize)

      return () => window.removeEventListener('resize', resize)
    }
    //eslint-disable-next-line
  }, [isWindow])

  return windowWidth
}

function detectMobile() {
  const width = getScreenWidth()

  if (!width) return false

  return width < 743
}

function detectLgUp() {
  const width = getScreenWidth()

  if (!width) return false

  return width > 1123
}

function detectMedDown() {
  const width = getScreenWidth()

  if (!width) return false

  return width <= 1123
}

function detectSmallDown() {
  const width = getScreenWidth()

  if (!width) return false

  return width <= 743
}

function detectXs() {
  const width = getScreenWidth()

  if (!width) return false

  return width < 430
}

export {
  getScreenWidth,
  detectMobile,
  detectLgUp,
  detectMedDown,
  detectXs,
  detectSmallDown,
}
