import { ContentNodeType, ReleaseRecommendation } from '@/lib/types'
import shuffleArray from '../utils/shuffleArray'
import { fetchReleasesForHub } from './fetchReleasesForHub'

export const fetchRecommendedReleasesForRelease = async (
  releasePublicKey: string,
): Promise<ReleaseRecommendation[]> => {
  const recommendationsResponse = await fetch(
    `${process.env.NINA_RECOMMENDATIONS_ENDPOINT}/release/${releasePublicKey}`,
    { next: { revalidate: 3600 } }, // get new recommendations every hour
  )

  let recommendations = await recommendationsResponse.json()
  recommendations = recommendations.data.recommendations.map(
    (recommendation: ReleaseRecommendation) => {
      recommendation.type = ContentNodeType.Release
      recommendation.datetime = recommendation.metadata.properties.date

      return recommendation
    },
  )

  // get nina's picks if no recommendations
  if (recommendations.length === 0) {
    const { releases } = await fetchReleasesForHub({
      publicKey: 'ninas-picks',
      pagination: { limit: 100, offset: 0, sort: 'desc' },
      withAccountData: false,
    })

    if (releases.length > 0) {
      shuffleArray(releases)
      recommendations = releases.slice(0, 6)
    }
  }

  return recommendations
}
