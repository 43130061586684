'use client'

import { createContext, useState } from 'react'

interface UpdateContextValues {
  date: number
  update: () => void
}

const UpdateContext = createContext<UpdateContextValues>({
  date: Date.now(),
  update: () => undefined,
})

const UpdateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [date, setDate] = useState(Date.now())

  const update = () => {
    setDate(Date.now())
  }

  return (
    <UpdateContext.Provider value={{ date, update }}>
      {children}
    </UpdateContext.Provider>
  )
}

export default {
  Context: UpdateContext,
  Provider: UpdateContextProvider,
}
