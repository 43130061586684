import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, PaginationProps, Release } from '../types'

export const preload = async (
  publicKey: string,
  pagination = { limit: 5, offset: 0, sort: 'desc' },
  withAccountData = true,
) => {
  void fetchReleasesPublishedByAccount({
    publicKey,
    pagination,
    withAccountData,
  })
}

export const fetchReleasesPublishedByAccount = cache(
  async ({
    publicKey,
    pagination = { limit: 5, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  }: {
    publicKey: string
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    await initSdk()

    const response = await Nina.Account.fetchPublished(
      publicKey,
      pagination,
      withAccountData,
    )

    if (response.published.length > 0) {
      response.published = response.published.map((release: Release) => {
        release.type = ContentNodeType.Release

        return release
      })
    }

    return response
  },
)
